import { ServiceKey } from "../../stores/SettingsStore/interfaces";
import { IApiMultiLanguage, IMultiLanguage } from "../LocationModel/interfaces";
import { IApiBundle } from "../BundleModel/interfaces";
import { Language } from "@/services/I18nService/interfaces";

export interface IAcademyServiceModel {
  readonly id: number;
  readonly name: IMultiLanguage;
  readonly description: IMultiLanguage;
  readonly packageLessonsQty: number;
  readonly price: number;
  readonly priceWithVat: number; // price with tax
  readonly isExchangeable?: boolean;
  readonly exchangeables?: { [k: number]: number[] };
  readonly locations: number[];
  readonly ownLocations: number[];
  readonly regions: number[];
  readonly isActive: boolean;
  readonly isVisible: boolean;
  readonly productType: ProductType;
  readonly position: number;
  readonly positionInLocation: ReadonlyMap<number, number>;
  readonly languageCode?: string;

  readonly isClass: boolean;
  readonly availableUnitsIds: number[];
  readonly vehicleType?: VehicleType;
  readonly meta?: {
    readonly key: ServiceKey;
    readonly type: AcademyServiceTypes;
    readonly duration?: number;
  };
  onTop: boolean;
  sortIndex: number;
  mustBeBookedOnPurchase?: boolean;
  serviceToBook?: {
    serviceId: number;
    locationId: number;
  };
}

export enum MotorcycleLessonType {
  Lesson = "lesson",
  Maneuver = "maneuver",
  DrivingExam = "drivingExam",
}

export enum VehicleType {
  car = "car",
  mc = "mc",
  Moped = "moped",
  Car = "car",
  Mc = "mc",
}

export enum ProductType {
  Bundle = "Bundle",
  Service = "Service",
  Giftcard = "Giftcard",
}

export enum AcademyServiceType {
  Lesson,
  Course,
  Test,
}
export enum AcademyServiceTypes { // fixme naming
  Lesson = "lesson",
  Class = "class",
  Test = "test",
}

export interface ICoreService {
  associationsIds: {
    instructors: number[];
    locations: number[];
    ownLocations: number[];
    regions: number[];
    exchangeableLocationsToServicesMapping: {
      [k: number]: number[];
    };
  };
  id: number;
  name: IApiMultiLanguage;
  description: IApiMultiLanguage;
  image: string | null;
  isActive: boolean;
  isVisible: boolean;
  isExchangeable: boolean;
  price: number;
  priceWithVat: number;
  type: AcademyServiceTypes;
  packageLessonsQty: number;
  duration: number;
  key: ServiceKey;
  inRegionsSortingIndexes: {
    [regionId: string]: number;
  };
  productType?: ProductType;
  vehicleType?: VehicleType;
  languageCode?: Language;
  mustBeBookedOnPurchase?: boolean;
  serviceToBook?: {
    serviceId: number;
    locationId: number;
  };
}

export type ICoreServiceSortable = (ICoreService | IApiBundle) & {
  onTop: boolean;
  sortIndex: number | undefined;
};

export interface ICoreServiceSort {
  createdAt: string;
  deletedAt: string;
  id: number;
  productId: number;
  productType: string;
  regionId: number;
  sortIndex: number;
  updatedAt: string;
}

export function isService(service: unknown): service is ICoreService {
  return (
    (service as any).type === AcademyServiceTypes.Class ||
    (service as any).type === AcademyServiceTypes.Lesson
  );
}
